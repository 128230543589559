import React from 'react';
import {
  WarningAmberOutlined as WarningAmberOutlinedIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { Snackbar } from 'components';

export const SNACKBAR_CONFIG = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  maxSnack: 5,
  Components: {
    default: Snackbar,
    success: Snackbar,
    error: Snackbar,
    warning: Snackbar,
    info: Snackbar,
  },
};

export const TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const ICON = {
  [TYPE.SUCCESS]: (<CheckCircleOutlineIcon className="alert-icon" sx={{ color: '#4CAF50' }} />),
  [TYPE.ERROR]: (<InfoOutlinedIcon className="alert-icon" sx={{ color: '#F44336' }} />),
  [TYPE.WARNING]: (<WarningAmberOutlinedIcon className="alert-icon" sx={{ color: '#F5B700' }} />),
  [TYPE.INFO]: (<InfoOutlinedIcon className="alert-icon" sx={{ color: '#2196F3' }} />),
};

export const HEADING = {
  [TYPE.SUCCESS]: 'Success!',
  [TYPE.ERROR]: 'Error!',
  [TYPE.WARNING]: 'Warning!',
  [TYPE.INFO]: 'Info',
};
